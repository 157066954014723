<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-content>
          <device-listeners-crud />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import DeviceListenersCrud from "../../components/EventListeners/DeviceListenersCrud";
export default {
  components: {
    DeviceListenersCrud,
  },
};
</script>